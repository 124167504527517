import type { DependencyList } from 'react'
import { useEffect } from 'react'

export const publish = (eventName: string) => {
  const event = new CustomEvent(eventName)
  document.dispatchEvent(event)
}

const useDomEvent = (eventName: string, callback: (e?: CustomEvent) => void, deps?: DependencyList) => {
  useEffect(() => {
    document.addEventListener(eventName, callback)

    return () => {
      document.removeEventListener(eventName, callback)
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, deps || [])
}

export default useDomEvent
