import { useCallback } from 'react'
import cx from 'classnames'
import { IconButton } from '@patrianna/core-components'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { addFavoriteGame, isFavoriteGameSelector, removeFavoriteGame } from 'store/modules/categoryGames'
import type { GameProduct, GameProductShort } from '@patrianna/shared-patrianna-types/store/GameModule'
import classes from './styles.module.scss'
import { useAuth } from 'app/context/auth'

export type FavouriteButtonProps = {
  gameProduct: GameProduct | GameProductShort
  className?: string
}

export default function FavoriteButton({ gameProduct, className }: FavouriteButtonProps) {
  const { isLoggedIn } = useAuth()
  const isFavorite = useAppSelector((state) => isFavoriteGameSelector(state, gameProduct?.code))
  const dispatch = useAppDispatch()

  const onFavoriteClick = useCallback(() => {
    if (isFavorite) {
      dispatch(removeFavoriteGame(gameProduct.code))
    } else {
      dispatch(addFavoriteGame(gameProduct))
    }
  }, [isFavorite, gameProduct])

  if (!gameProduct || !isLoggedIn) {
    return null
  }

  return (
    <IconButton
      className={cx(classes.favoriteButton, className)}
      onClick={onFavoriteClick}
      data-test='favourite-button'
    >
      <img
        src={`${process.env.IMG_ORIGIN}/common/${isFavorite ? 'favorite' : 'favorite_shallow'}.svg`}
        width={16}
        height={16}
        alt='favorites icon'
      />
    </IconButton>
  )
}
