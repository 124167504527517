export const LINKS_RECORD: {
  [key: string]: {
    title: string
    link: string
  }
} = {
  link_support: {
    title: 'support',
    link: process.env.ZENDESK_SUPPORT_LINK,
  },
}

export const LINK_REGEX = /\{(.*?)\}/gi

export const getLinkObjectFromMessage = (message: string) => {
  var linkObject: Record<string, string> | undefined = undefined

  Object.keys(LINKS_RECORD).forEach((key) => {
    if (message.includes(`{${key}}`)) {
      linkObject = {
        link: LINKS_RECORD[key].link,
        title: LINKS_RECORD[key].title,
        key,
      }
    }
  })

  return linkObject || message
}
