import cx from 'classnames'
import { CircleLoader } from '@patrianna/core-components'
import classes from './styles.module.scss'

export default function Loader(props: any) {
  // eslint-disable-next-line
  const { isLoading, pastDelay, timedOut, retry, fullscreen, thickness = 4, className, ...restProps } = props

  return (
    <div className={cx(classes.loader, fullscreen && classes.fullscreen, className)}>
      <CircleLoader thickness={thickness} {...restProps} />
    </div>
  )
}

export function LoaderFullscreen(props: any) {
  return <Loader fullscreen {...props} />
}
