import classes from './styles.module.scss'
import CountdownIcon from './CountdownIcon'

type Props = {
  label: string
  pendingCountdown: string
  countdown: string
  loading: boolean
  readyLabel: string
}

function Countdown({ loading, label, pendingCountdown, countdown, readyLabel }: Props) {
  return countdown || loading ? (
    <div className={classes.countdown}>
      <div className={classes.countdownText}>{label}</div>
      <div>
        <div>{loading ? pendingCountdown : countdown}</div>
        <CountdownIcon className={classes.clockIcon} />
      </div>
    </div>
  ) : (
    readyLabel
  )
}

export default Countdown
