import cx from 'classnames'
import { Snackbar } from '@patrianna/core-components'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { closeSnackbar } from 'store/modules/snackbar/actions'
import {
  getPositionHorizontalSelector,
  getPositionVerticalSelector,
  getSnackbarActionButtonSelector,
  getSnackbarAutoHideSelector,
  getSnackbarComponentPropsSelector,
  getSnackbarMessageAlignSelector,
  getSnackbarMessageSelector,
  getSnackbarVariantSelector,
  getSnackbarVisibilitySelector,
} from 'store/modules/snackbar/selectors'
import classes from './styles.module.scss'
import SnackbarContent from './SnackbarContent'

function GlobalSnackbar() {
  const isSnackbarVisible = useAppSelector(getSnackbarVisibilitySelector)
  const message = useAppSelector(getSnackbarMessageSelector)
  const variant = useAppSelector(getSnackbarVariantSelector)
  const actionButton = useAppSelector(getSnackbarActionButtonSelector)
  const autoHide = useAppSelector(getSnackbarAutoHideSelector)
  const messageAlign = useAppSelector(getSnackbarMessageAlignSelector)
  const messageProps = useAppSelector(getSnackbarComponentPropsSelector)
  const positionVertical = useAppSelector(getPositionVerticalSelector)
  const positionHorizontal = useAppSelector(getPositionHorizontalSelector)
  const dispatch = useAppDispatch()
  const CustomComponent = message
  const isMessageString = typeof message === 'string'

  if (!CustomComponent) {
    return null
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: positionVertical || 'bottom',
        horizontal: positionHorizontal || 'center',
      }}
      open={isSnackbarVisible}
      onClose={() => {
        setTimeout(() => {
          dispatch(closeSnackbar())
        }, autoHide)
      }}
      autoHideDuration={autoHide || 4000}
      className={cx(!!isMessageString && classes.modalLikeSnackbar)}
      disableAnchoring={isMessageString}
    >
      {isMessageString ? (
        // @ts-ignore
        <SnackbarContent variant={variant} message={message} actionButton={actionButton} messageAlign={messageAlign} />
      ) : (
        // @ts-ignore
        <CustomComponent {...messageProps} />
      )}
    </Snackbar>
  )
}

export default GlobalSnackbar
