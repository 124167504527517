import cx from 'classnames'
import classes from './styles.module.scss'

type Props = {
  className?: string
}

const CountdownIcon = ({ className }: Props) => {
  return (
    <svg
      className={cx(classes.icon, className)}
      xmlns='http://www.w3.org/2000/svg'
      width='13'
      height='13'
      viewBox='0 0 13 13'
      fill='none'
    >
      <path d='M7.10938 5.53662H9.54688C9.70849 5.53662 9.86349 5.60082 9.97777 5.7151C10.092 5.82938 10.1562 5.98438 10.1562 6.146C10.1562 6.30761 10.092 6.46261 9.97777 6.57689C9.86349 6.69117 9.70849 6.75537 9.54688 6.75537H6.5C6.33838 6.75537 6.18339 6.69117 6.06911 6.57689C5.95483 6.46261 5.89062 6.30761 5.89062 6.146V2.48975C5.89062 2.32813 5.95483 2.17313 6.06911 2.05885C6.18339 1.94457 6.33838 1.88037 6.5 1.88037C6.66162 1.88037 6.81661 1.94457 6.93089 2.05885C7.04517 2.17313 7.10938 2.32813 7.10938 2.48975V5.53662ZM6.5 12.2397C3.13442 12.2397 0.40625 9.51157 0.40625 6.146C0.40625 2.78042 3.13442 0.0522461 6.5 0.0522461C9.86558 0.0522461 12.5938 2.78042 12.5938 6.146C12.5938 9.51157 9.86558 12.2397 6.5 12.2397ZM6.5 11.021C7.79293 11.021 9.03291 10.5074 9.94715 9.59314C10.8614 8.6789 11.375 7.43893 11.375 6.146C11.375 4.85307 10.8614 3.61309 9.94715 2.69885C9.03291 1.78461 7.79293 1.271 6.5 1.271C5.20707 1.271 3.96709 1.78461 3.05285 2.69885C2.13861 3.61309 1.625 4.85307 1.625 6.146C1.625 7.43893 2.13861 8.6789 3.05285 9.59314C3.96709 10.5074 5.20707 11.021 6.5 11.021Z' />
    </svg>
  )
}

export default CountdownIcon
