import { actions } from 'store/modules/gcGenerator/slice'
import type { TypedThunk } from 'src/store'
import { getGcGeneratorEnabledForUser } from 'store/modules/gcGenerator/selectors'
import type { AcceptFreeGoldCoinsRequest, GetFreeGoldCoinsStatusResponse } from 'store/modules/gcGenerator/types'
import { openDialog } from 'store/modules/dialog/actions'

export const {
  setGcGeneratorSettings,
  setGcGeneratorAccepting,
  setGcGeneratorAccepted,
  setGcGeneratorEnabled,
  setGcGeneratorLoading,
} = actions

export const requestGoldCoinsGenerator = (): TypedThunk => async (dispatch, getState, extraArgs) => {
  const isGcGeneratorEnabledForUser = getGcGeneratorEnabledForUser(getState())

  if (!isGcGeneratorEnabledForUser) {
    return
  }

  try {
    dispatch(setGcGeneratorLoading({ loading: true }))
    const body = await extraArgs.gateway.emit<GetFreeGoldCoinsStatusResponse>({ type: 'GetFreeGoldCoinsStatusRequest' })
    const { accepted, settings } = body
    dispatch(setGcGeneratorAccepted({ accepted }))
    dispatch(setGcGeneratorSettings(settings))
  } catch (error) {
    console.log(error)
    dispatch(setGcGeneratorSettings({ interval: 0, amount: 0 }))
  } finally {
    dispatch(setGcGeneratorLoading({ loading: false }))
  }
}

export const requestGoldCoinsGeneratorAccept = (): TypedThunk => async (dispatch, getState, extraArgs) => {
  const isGcGeneratorEnabledForUser = getGcGeneratorEnabledForUser(getState())
  if (!isGcGeneratorEnabledForUser) {
    return
  }

  try {
    dispatch(setGcGeneratorAccepting({ accepting: true }))
    const data: AcceptFreeGoldCoinsRequest = {
      type: 'AcceptFreeGoldCoinsRequest',
    }

    await extraArgs.gateway.emit(data)
    await requestGoldCoinsGenerator()(dispatch, getState, extraArgs)
    dispatch(openDialog({ modalName: 'GOLD_COIN_GENERATOR_SUCCESSFUL_DIALOG' }))
  } catch (error) {
    console.log(error)
  } finally {
    dispatch(setGcGeneratorAccepting({ accepting: false }))
  }
}
