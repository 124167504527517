import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { GcGeneratorSettings, GcGeneratorState } from './types'

const initialState: GcGeneratorState = {
  enabled: false,
  loading: true,
  settings: {
    interval: 0,
    amount: 0,
  },
  accepting: false,
  accepted: 0,
}

export const gcGeneratorSlice = createSlice({
  name: 'gcGenerator',
  initialState,
  reducers: {
    setGcGeneratorSettings: (state, action: PayloadAction<GcGeneratorSettings>) => {
      state.settings = action.payload
    },
    setGcGeneratorAccepting: (state, action: PayloadAction<{ accepting: boolean }>) => {
      state.accepting = action.payload.accepting
    },
    setGcGeneratorAccepted: (state, action: PayloadAction<{ accepted: number }>) => {
      state.accepted = action.payload.accepted
    },
    setGcGeneratorEnabled: (state, action: PayloadAction<{ enabled: boolean }>) => {
      state.enabled = action.payload.enabled
    },
    setGcGeneratorLoading: (state, action: PayloadAction<{ loading: boolean }>) => {
      state.loading = action.payload.loading
    },
  },
})

export type ActionsTypes = ReturnType<InferValueTypes<typeof gcGeneratorSlice.actions>>
export const { actions } = gcGeneratorSlice
