import type { FC, ReactElement } from 'react'
import type { ButtonPropsType } from 'components/Button'
import Button from 'components/Button'
import { useCountdown } from 'hooks/useCountdown'
import Countdown from 'components/GoldCoinGeneratorButton/Countdown'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  getGcGeneratorAccepting,
  getGcGeneratorAmount,
  getGcGeneratorIntervalDate,
  getGcGeneratorLoading,
} from 'store/modules/gcGenerator/selectors'
import { requestGoldCoinsGeneratorAccept } from 'store/modules/gcGenerator/actions'
import { useTranslation } from 'app/context/translations'
import cx from 'classnames'
import classes from './styles.module.scss'
import { trackEvent } from 'config/analytic'

export type GoldCoinGeneratorButtonProps = ButtonPropsType & {
  dataTest?: string
  CustomButton?: FC<ButtonPropsType>
}

function GoldCoinGeneratorButton({
  className,
  CustomButton,
  dataTest,
  ...rest
}: GoldCoinGeneratorButtonProps): ReactElement {
  const accepting = useAppSelector(getGcGeneratorAccepting)
  const isDataLoading = useAppSelector(getGcGeneratorLoading)
  const gcAmount = useAppSelector(getGcGeneratorAmount)
  const eventDate = useAppSelector(getGcGeneratorIntervalDate)
  const { countdown, pendingCountdown } = useCountdown(eventDate, true)
  const dispatch = useAppDispatch()
  const t = useTranslation()
  const disabled = !!countdown || accepting || isDataLoading
  const handleClick = () => {
    trackEvent('gc_generator_accepted', {
      label: gcAmount,
    })
    dispatch(requestGoldCoinsGeneratorAccept())
  }

  return CustomButton ? (
    <CustomButton
      {...rest}
      data-test={dataTest}
      onClick={handleClick}
      disabled={disabled}
      className={cx(className, { [classes.countdown]: !!countdown })}
    />
  ) : (
    <Button
      {...rest}
      data-test={dataTest}
      onClick={handleClick}
      disabled={disabled}
      className={cx(className, { [classes.countdown]: !!countdown })}
    >
      <Countdown
        label={t('common.unlocks_in')}
        countdown={countdown}
        pendingCountdown={pendingCountdown}
        loading={accepting}
        readyLabel={t('common.get_coins')}
      />
    </Button>
  )
}

export default GoldCoinGeneratorButton
