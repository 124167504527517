import cx from 'classnames'
import { IconButton } from '@patrianna/core-components'
import classes from './styles.module.scss'

export type BackButtonType = {
  handler?: Function
  className?: string
  color?: 'white' | 'black'
  children?: any
}

export default function BackButton(props: BackButtonType) {
  return (
    <IconButton
      className={cx(props.className, classes.root, classes[props?.color || 'black'])}
      onClick={() => {
        props.handler?.()
      }}
      data-test='modal-back-button'
    >
      <img
        className={classes.icon}
        src={`${process.env.IMG_ORIGIN}/common/left.svg`}
        width={16}
        height={16}
        alt='arrow-left'
      />

      {props.children}
    </IconButton>
  )
}
