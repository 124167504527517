import type { ReactNode } from 'react'
import { Fragment } from 'react'
import { LINK_REGEX } from './utils'

function MessageWithLink({
  message,
  linkOptions,
}: {
  message: string
  linkOptions: {
    title: string
    link: string
    key: string
  }
}) {
  const parcedMessage: Array<ReactNode> = [...message.split(LINK_REGEX)]

  return (
    <>
      {parcedMessage.map((el, index) => {
        if (el === linkOptions.key) {
          return (
            <a href={linkOptions.link} key={index} target='_blank' rel='noreferrer'>
              {linkOptions.title}
            </a>
          )
        }

        return <Fragment key={index}>{el}</Fragment>
      })}
    </>
  )
}

export default MessageWithLink
