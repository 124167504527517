import cx from 'classnames'
import { SnackbarContent } from '@patrianna/core-components'
import type {
  MessageAlign,
  SnackbarActionButton,
  SnackbarVariants,
} from '@patrianna/shared-patrianna-types/store/SnackbarModule'
import AnimatedOverlay from 'dialogs/components/AnimatedOverlay'
import classes from './styles.module.scss'
import RichMessage from './RichMessage'
import { useAppDispatch } from 'src/store/hooks'
import { closeSnackbar } from 'src/store/modules/snackbar/actions'

export interface SnackbarProps {
  className?: string
  message?: string
  variant: SnackbarVariants
  actionButton?: SnackbarActionButton | null
  messageAlign: MessageAlign
}

function Snackbar(props: SnackbarProps) {
  const { message, variant, messageAlign, ...other } = props

  const dispatch = useAppDispatch()

  const handleOverlayClick = () => {
    dispatch(closeSnackbar())
  }

  return (
    <AnimatedOverlay mobileDirection='center' desktopDirection='center' overlayHandler={handleOverlayClick}>
      <SnackbarContent
        className={cx(classes[messageAlign], classes.modalLikeSnackbarContent)}
        message={<RichMessage variant={variant} message={message} />}
        {...other}
      />
    </AnimatedOverlay>
  )
}

export default Snackbar
