import type { ReactNode } from 'react'
import { useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import { useMediaQuery } from '@patrianna/shared-hooks'
import { useAppDispatch } from 'src/store/hooks'
import { closeLatestDialog } from 'store/modules/dialog/actions'
import classes from './styles.module.scss'
import AnimationOverlayContext from './AnimationOverlayContext'
import { DELAY } from './config'
import type { AnimationTypes } from './types'

const animateSlide: Record<string, string> = {
  up: classes.slideUp,
  down: classes.slideBottom,
  left: classes.slideLeft,
  right: classes.slideRight,
}

const hideAnimateSlide: Record<string, string | undefined> = {
  up: classes.hideSlideUp,
  down: classes.hideSlideBottom,
  left: classes.hideSlideLeft,
  right: classes.hideSlideRight,
}

export type Props = {
  mobileDirection?: AnimationTypes
  desktopDirection?: AnimationTypes
  overlayClass?: string
  children?: ReactNode
  disableOverlayClose?: boolean
  closeDialogByOverlayHandler?: () => void
  overlayHandler?: () => void
}

// @TODO added touch event for modal overlay event, test it
function AnimatedOverlay(props: Props) {
  const [visible, changeVisibility] = useState(false)
  const dispatch = useAppDispatch()
  const isMobile = useMediaQuery('xs')
  const direction = isMobile ? props.mobileDirection : props.desktopDirection

  const animate = direction === 'center' ? classes.grow : classes.slide
  const animateHide = direction === 'center' ? classes.hideGrow : classes.hideSlide

  useEffect(() => {
    changeVisibility(true)
  }, [])

  const closeModal = () => {
    changeVisibility(false)
    setTimeout(() => {
      dispatch(closeLatestDialog())
    }, DELAY)
    props.closeDialogByOverlayHandler?.()
  }

  const overlayHandler = (e: any) => {
    // detect click on overlay and close current modal
    if (!props.disableOverlayClose) {
      if (e.target.getAttribute('data-animated-overlay')) {
        if (typeof props?.overlayHandler === 'function') {
          props?.overlayHandler()
        } else {
          closeModal()
        }
      }
    }
  }

  const visibilityHandler = useMemo(
    () => ({ visibilityHandler: closeModal }),
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
    [changeVisibility]
  )

  return (
    <AnimationOverlayContext.Provider value={visibilityHandler}>
      <div
        onMouseDown={overlayHandler}
        data-animated-overlay
        data-direction={direction}
        className={cx(
          direction && animateSlide[direction],
          !visible && animateHide,
          !visible && direction && hideAnimateSlide[direction],
          classes.overlay,
          animate,
          props.overlayClass
        )}
        role='button'
      >
        {props.children}
      </div>
    </AnimationOverlayContext.Provider>
  )
}

export default AnimatedOverlay
